<template>
  <div :style="{ color: color }">
    <h2>{{ props.text || "Произошла ошибка" }}  </h2>
    <h3>{{ props.text2 }}</h3>
    <h3>{{ props.loadingError }}</h3>
  </div>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";

const props = defineProps({
  loadingError: String,
  text: String,
  text2: String,
  color: String,
});
</script>
<style scoped lang="scss">
div {
  max-width: 370px;
  margin: 20px ;
  background-color: rgba(246, 246, 246, 0.184);
  border-radius: 10px;
  padding: 20px;
}
h3 {
  margin-top: 20px;
}
</style>
