/* eslint-disable */
// @generated by protobuf-ts 2.9.3 with parameter generate_dependencies,eslint_disable,ts_nocheck
// @generated from protobuf file "acquiring_donate.proto" (package "DonateAcquiring", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Acquiring } from "./acquiring_donate";
import type { CreatePaymentResponse } from "./acquiring_donate";
import type { CreatePaymentRequest } from "./acquiring_donate";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetInfoResponse } from "./acquiring_donate";
import type { GetInfoRequest } from "./acquiring_donate";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service DonateAcquiring.Acquiring
 */
export interface IAcquiringClient {
    /**
     * @generated from protobuf rpc: GetInfo(DonateAcquiring.GetInfoRequest) returns (DonateAcquiring.GetInfoResponse);
     */
    getInfo(input: GetInfoRequest, options?: RpcOptions): UnaryCall<GetInfoRequest, GetInfoResponse>;
    /**
     * @generated from protobuf rpc: CreatePayment(DonateAcquiring.CreatePaymentRequest) returns (DonateAcquiring.CreatePaymentResponse);
     */
    createPayment(input: CreatePaymentRequest, options?: RpcOptions): UnaryCall<CreatePaymentRequest, CreatePaymentResponse>;
}
/**
 * @generated from protobuf service DonateAcquiring.Acquiring
 */
export class AcquiringClient implements IAcquiringClient, ServiceInfo {
    typeName = Acquiring.typeName;
    methods = Acquiring.methods;
    options = Acquiring.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetInfo(DonateAcquiring.GetInfoRequest) returns (DonateAcquiring.GetInfoResponse);
     */
    getInfo(input: GetInfoRequest, options?: RpcOptions): UnaryCall<GetInfoRequest, GetInfoResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInfoRequest, GetInfoResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePayment(DonateAcquiring.CreatePaymentRequest) returns (DonateAcquiring.CreatePaymentResponse);
     */
    createPayment(input: CreatePaymentRequest, options?: RpcOptions): UnaryCall<CreatePaymentRequest, CreatePaymentResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreatePaymentRequest, CreatePaymentResponse>("unary", this._transport, method, opt, input);
    }
}
