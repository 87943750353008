import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

// const routes: Array<RouteRecordRaw> =

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:catchAll(.*)*',
      beforeEnter: (to, from, next) => {
        window.location.href = 'https://paytaxi.ru/'
      },
      component: HomeView,
    },
    {
      path: '/p',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/success',
      name: 'success',
      component: () => import('../views/SuccessView.vue'),
    },
    {
      path: '/fail',
      name: 'fail',
      component: () => import('../views/FailView.vue'),
    },
  ],
})

export default router
